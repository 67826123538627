import loadScript from '../../modules/load-script';
import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const map = new WeakMap<Element, any>();
let youtubeLoaded = false;

const vkMap = new WeakMap<LitPopupElement, any>();

const onOpen = (event) => {
    const el = event.target;
    const instance = vkMap.get(el);
    if (instance) {
        instance.play();
    }
};

const onClose = (event) => {
    const el = event.target;
    const instance = vkMap.get(el);
    if (instance) {
        instance.pause();
    }
};

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<LitPopupElement>('app-lit-popup.js-video-popup'));

    elements.forEach((el) => {
        const vkIframe = el.querySelector('.js-vk-iframe');

        if (vkIframe) {
            const player = VK.VideoPlayer(vkIframe);

            vkMap.set(el, player);
        }

        el.addEventListener('open', onOpen);
        el.addEventListener('close', onClose);
    });

    if (elements.length > 0) {
        if (!youtubeLoaded) {
            loadScript('https://www.youtube.com/iframe_api').then(() => {
                youtubeLoaded = true;
            });
        }

        const onYoutubeReady = () => {
            elements.forEach((el) => {
                const liteYoutube = el.querySelector('lite-youtube');

                function playVideo() {
                    const iframe = liteYoutube?.querySelector('lite-youtube iframe');

                    if (iframe) {
                        const existingPlayer = map.get(iframe);

                        if (existingPlayer?.playVideo) {
                            existingPlayer.playVideo();
                        } else {
                            // control player via
                            // https://developers.google.com/youtube/iframe_api_reference
                            const player = new (window as any).YT.Player(iframe);
                            map.set(iframe, player);

                            player.addEventListener('onReady', () => {
                                if (el?.instance.isOpen) {
                                    player.playVideo();
                                }
                            });
                        }
                    }
                }

                function stopVideo() {
                    const iframe = liteYoutube?.querySelector('lite-youtube iframe');

                    if (iframe) {
                        const existingPlayer = map.get(iframe);
                        existingPlayer?.stopVideo?.();
                    }
                }

                liteYoutube?.addEventListener(
                    'click',
                    () => {
                        playVideo();
                        setTimeout(() => {
                            el.instance.on('open', playVideo);
                        }, 50);
                    },
                    { once: true },
                );

                el.instance.one('open', () => {
                    liteYoutube?.dispatchEvent(new Event('click'));
                });

                el.instance.on('close', stopVideo);
            });
        };

        if (youtubeLoaded) {
            onYoutubeReady();
        } else {
            (window as any).onYouTubeIframeAPIReady = onYoutubeReady;
        }
    }
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<LitPopupElement>('app-lit-popup.js-video-popup'));

    elements.forEach((el) => {
        const liteYoutube = el.querySelector('lite-youtube');
        const iframe = liteYoutube?.querySelector('lite-youtube iframe');

        if (iframe) {
            const player = map.get(iframe);

            if (player) {
                player.destroy();
                map.delete(iframe);
            }
        }

        const vkInstance = vkMap.get(el);
        if (vkInstance) {
            vkInstance.destroy();
            vkMap.delete(el);
        }

        el.removeEventListener('open', onOpen);
        el.removeEventListener('close', onClose);
    });
}

const _module = { init, destroy };

export default _module;
